<template>
  <div>
    <c-search-box @enter="getTables">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="tableItems"
            type="edit"
            itemText="tableName"
            itemValue="tableId"
            name="table"
            label="대상테이블"
            v-model="searchParam.table"
            required
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="gtypeItems"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="gtype"
            label="Generate Type"
            v-model="searchParam.gtype"
            required
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card title="Generate Result" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn label="MSSQL DDL변환" icon="save" @btnClicked="ConvertMsSql" />
              <c-btn label="검색" icon="search" @btnClicked="GeneratorTable" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-12" v-html="resultGenerate" id="resultGen">
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <br/>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-card title="mybatis parameter 변환" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn label="변환" icon="search" @btnClicked="GeneratorParam" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-12">
              <c-textarea
                :rows="20"
                label="DB 컬럼(구분자 ,)"
                name="insertParam"
                v-model="insertParam">
              </c-textarea>
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-card title="mybatis parameter Result" class="cardClassDetailForm">
          <template slot="card-detail">
            <div class="col-12" v-html="resultGenerateParam" id="resultGenParam">
            </div>
          </template>
        </c-card>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';

export default {
  name: 'db-generator',
  data() {
    return {
      dbtableListUrl: '',
      dbtableInfoUrl: '',
      resultGenerate: '',
      insertParam: '',
      resultGenerateParam: '',
      erdUrl: '',
      searchParam: {
        table: null,
        gtype: null,
      },
      tableItems: [],
      gtypeItems: [
        { code: 'class', codeName: 'model class' },
        { code: 'select', codeName: 'select query' },
        { code: 'insert', codeName: 'insert query' },
        { code: 'update', codeName: 'update query' },
        { code: 'jsongridhead', codeName: 'json-그리드헤더' },
        { code: 'jsondata', codeName: 'json-Data결과수신' },
        { code: 'jsondatacomment', codeName: 'json-Data결과수신(주석포함)' },
      ]
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.dbtableListUrl = selectConfig.devtool.dbtables.url;
      this.dbtableInfoUrl = selectConfig.devtool.dbtable.url;
      this.erdUrl = selectConfig.devtool.erd.url;
      this.getTables();
    },
    getTables() {
      this.$http.url = this.$format(
        this.dbtableListUrl
      );
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.tableItems = this.$_.clone(_result.data);
      });
    },
    GeneratorTable() {
      this.$http.url = this.$format(
        this.dbtableInfoUrl,
        this.searchParam.table,
        this.searchParam.gtype
      );
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.resultGenerate = _result.data.resultStr;
      });
    },
    ConvertMsSql() {
      this.$http.url = '/api/devtool/convert/mssql';
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        // this.resultGenerate = _result.data.resultStr;
        const filename = 'MSSQL DLL생성.sql';
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8, ' + encodeURIComponent(_result.data.resultStr));
        element.setAttribute('download', filename);
        document.body.appendChild(element);
        element.click();
      });
    },
    GeneratorParam() {
      let _datas = this.insertParam.replace(/\n/g, '').replace(/\s*/g, '').toLowerCase().split(',')
      let _result = '';
      let idx = 0;
      this.$_.forEach(_datas, _col => {
        _result += (idx > 0 ? ', ' : '') + '#{' + _col.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase()) + '}<br/>'
        idx++;
      })
      this.resultGenerateParam = _result;
    },
  }
};
</script>
